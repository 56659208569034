<template>
    <div class="newsletter-confirmation-info">
        <div class="icon-wrapper">
            <Icon :icon="Letter" width="64" height="64" />
        </div>
        <HeaderText :tag="HEADER_TAG" :size="headerSize" class="header">
            {{ $t('You are only one more step away from a discount!') }}
        </HeaderText>
        <BodyText class="text">
            {{
                $t(
                    // eslint-disable-next-line max-len
                    'We sent you an email with a link to confirm your Newsletter subscription. Click on it and we will send you a -15% discount on your purchases.'
                )
            }}
        </BodyText>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import Letter from '@static/icons/illustration/letter.svg?inline';

import {
    HeaderText,
    HEADER_TEXT_TAGS,
    HEADER_TEXT_SIZES,
} from '@modivo-ui/components/HeaderText/v1';
import { BodyText } from '@modivo-ui/components/BodyText/v1';
import { Icon } from '@modivo-ui/components/Icon/v1';

export default {
    name: 'NewsletterConfirmationInfo',

    components: {
        HeaderText,
        BodyText,
        Icon,
    },

    computed: {
        ...mapState(['isMobile']),

        headerSize() {
            const { M, L } = HEADER_TEXT_SIZES;

            return this.isMobile ? M : L;
        },
    },

    beforeCreate() {
        this.Letter = Letter;
        this.HEADER_TAG = HEADER_TEXT_TAGS.STRONG;
    },
};
</script>

<style lang="scss" scoped>
.newsletter-confirmation-info {
    @apply flex flex-col items-center;

    .icon-wrapper {
        @apply flex justify-center items-center rounded-50 w-ui-14 h-ui-14 bg-gray5;
    }

    .header {
        @apply mt-ui-4 text-center;
    }

    .text {
        @apply mt-ui-1 text-center;
    }

    @screen lg {
        .header {
            @apply mt-ui-6;
        }

        .text {
            @apply mt-ui-2 text-ui-18 leading-ui-24;
        }
    }
}
</style>
